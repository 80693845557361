<template>
  <div class="create-translations">
    <div class="translations-form">
      <div class="form-content content">
        <h3 v-if="isHeaderTitle" class="mb-3 bread-content">{{ stepNumber }}. {{ pageTitle }}</h3>
        <div v-if="ready">
          <!-- TABS -->
          <CRow class="mb-4">
            <CCol col="12" sm="11" class="d-flex flex-wrap align-items-center">
              <h6 class="mr-2">{{ texts.createPage.translations.titleSub }}</h6>
              <ul class="translation-tabs">
                <li v-for="(lang, index) in languages" :key="index" class="translation-tab-item">
                  <button
                    class="translation-tab-button"
                    :class="{'translation-tab-button__active': currentLang !== lang}"
                    @click="handleTranslation(lang)"
                  >
                   <span
                     v-if="translatableFieldsCount !== 0 && Object.values(formData[lang]).length === translatableFieldsCount"
                     :class="{'check-icon__active': currentLang !== lang}"
                     class='exclaimIcon check-icon'
                   >
                     <i class="fa fa-check"></i>
                   </span>
                    <span
                      v-else-if="Object.values(formData[lang]).length < translatableFieldsCount && Object.values(formData[lang]).length > 0"
                      :class="{'exclaimPartialCompleteIcon__active': currentLang !== lang}"
                      class="exclaimPartialCompleteIcon"
                    >
                    &#33;
                    </span>
                    <span
                      v-else
                      :class="{'exclaim__active': currentLang !== lang}"
                      class="exclaimIcon"
                    >
                    &#33;
                    </span>
                    <span :class="{'translation-tab-lang__active': currentLang !== lang}">{{ lang }}</span>
                  </button>
                </li>
              </ul>
            </CCol>
          </CRow>
          <!--  Form Content    -->
          <div v-if="currentLang">
            <ClPageToolbar :isLeftSide='false' @toggleEditorToolbar="handleToggleEditorToolbar"  />
            <div v-for="(val, key, index) in langData[currentLang]" :key="index">
              <h6 class="mb-2">{{ key }}</h6>
              <CRow class="mb-4 " v-if="!editorFields.includes(key)">
                <CCol col="12" md="5" class="text-left ">
                  <CInput
                    v-model="entityData[key]"
                    add-input-classes="col-sm-12"
                    disabled="true"
                  />
                </CCol>
                <CCol class="d-flex flex-column align-items-center text-center  mb-2">
                  <CIcon name="cil-arrow-right" class='check-icon' />
                  <span>  to {{ currentLang }}</span>
                </CCol>
                <CCol col="12" md="5" class="">
<!--                  <CIInput-->
<!--                    :value="formData[currentLang][key]"-->
<!--                    :name="key"-->
<!--                    @input="handleInput"-->
<!--                    add-input-classes="col-sm-12"-->
<!--                    placeholder="Insert text here..."-->
<!--                    :disabled="false"-->
<!--                    :isTooltip="false"-->
<!--                    :isLabel="false"-->
<!--                    :isTranslation="true"-->
<!--                    :xlContent="'12'"-->
<!--                  />-->
                  <ClEditor
                    :name="key"
                    :value-prop="formData[currentLang][key]"
                    :disabled="false"
                    :isTranslation="true"
                    :isToolbar="isToolbar"
                    @change="handleInput"
                    :style="{maxHeight: '100px', overflow: 'hidden'}"
                  />
                </CCol>
              </CRow>
              <CRow class="mb-4" v-else>
                <CCol col="12" md="5">
                  <ClEditor
                    :disabled="true"
                    :value-prop="entityData[key]"
                  />
                </CCol>
                <CCol class="d-flex flex-column align-items-center text-center mb-2">
                  <CIcon name="cil-arrow-right" class='check-icon' />
                  <span>  to {{ currentLang }}</span>
                </CCol>
                <CCol col="12" md="5">
                  <ClEditor
                    :name="key"
                    :value-prop="formData[currentLang][key]"
                    :disabled="false"
                    :isTranslation="true"
                    :isToolbar="isToolbar"
                    @change="handleInput"
                  />
                </CCol>
              </CRow>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClEditor from "@/shared/components/formComponents/ClEditor";
import CIInput from '@/shared/components/formComponents/CIInput';
import ClPageToolbar from "@/shared/components/ClPageToolbar";
import { achievementsTexts } from "@/config/pageTexts/achievements.json";
import { getLanguagesData} from "@/utils/translationsUtils";
import { cloneDeep, omit } from "lodash";
import { mapActions, mapGetters } from "vuex";
import { pageConfig } from "@/config";

export default {
  name: "CreateTranslations",
  components: {
    ClEditor,
    CIInput,
    ClPageToolbar,
  },
  props: {
    stepNumber: {
      type: Number,
      default: 7
    },
    pageTitle: {
      type: String,
      default: 'Translations'
    },
    entityData: Object,
    translatableFields: {
      type: Array,
      default: () => [],
    },
    translationsData: {
      type: Object,
      default: () => {},
    },
    isHeaderTitle: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      ready: false,
      langData: {},
      currentLang: '',
      languages: [],
      editorFields: ['description', 'termsAndConditions'],
      availableLanguages: [],
      formData: {},
      languagesMap: {},
      texts: {
        ...achievementsTexts
      },
      translatableFieldsCount: 0,
      isToolbar: false,
      sortBy: pageConfig.sortBy,
      page: pageConfig.page,
    }
  },
  mounted() {
    this.initialize();
  },
  computed: {
    ...mapGetters('languages', {storeLanguages: 'languages'}),
  },
  watch: {
    formData: {
      deep: true,
      handler(val) {
        this.$emit('updated', {val, map: this.languagesMap})
      }
    },
  },
  methods: {
    ...mapActions('languages', ['handleGetLanguagesByQuery']),
    async initialize() {
      if (this.storeLanguages.length) {
        this.availableLanguages = cloneDeep(this.storeLanguages);
      } else {
        this.availableLanguages = await this.handleGetLanguagesByQuery({
          queryRequest: {
            sortBy: this.sortBy,
            limit: 50,
            skip: 0
          }
        });
      }

      if (!this.availableLanguages || !this.availableLanguages.length) {
        this.ready = true;
        return;
      }

      this.languages = this.getLanguages(this.availableLanguages);
      this.currentLang = this.availableLanguages[0].name;
      this.langData = this.getLengData(this.languages);
      this.formData = getLanguagesData(this.languages);
      this.translatableFieldsCount = this.translatableFields.length
      this.prepareLangData();
      this.ready = true;
    },
    getLanguages(availableLanguages) {
      let languages = [];
      availableLanguages.forEach(language => {
        languages.push(language.name);
        this.languagesMap[language.name] = language.key;
      })
      return languages;
    },
    getLengData(languages) {
      let lengData = {};
      languages.forEach(language => {
        lengData[language] = {}
      })

      return lengData;
    },
    handleTranslation(lang) {
      this.currentLang = lang;
    },
    prepareLangData() {
      for (let langKey in this.langData) {
        for (let entityKey in this.entityData) {
          if (this.translatableFields.includes(entityKey)) {
            this.langData[langKey][entityKey] = this.entityData[entityKey];
          }
        }
      }
      if (Object.keys(this.translationsData).length) {
        this.formData = cloneDeep(this.translationsData)
      }
    },
    handleInput(data) {
      this.fieldsHandler(data.value, data.name);
    },
    fieldsHandler(value, field) {
      if (!value) {
        this.formData[this.currentLang] = omit(this.formData[this.currentLang], field)
      } else {
        this.formData[this.currentLang] = {
          ...this.formData[this.currentLang],
          [field]: value.trim()
        }
      }
    },
    handleToggleEditorToolbar(val) {
      this.isToolbar = val;
    },
  }
}
</script>

<style lang="scss">
.create-translations {
  height: 100%;
  .translations-form {
    height: calc(100% - 50px);
    input::placeholder {
      font-style: italic;
    }
    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;
    }
    .ql-toolbar, .ql-snow {
      background: white;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;

    }
    .ql-container.ql-snow {
      height: 100px;
      margin-bottom: 1rem;
      background: white;
      color: black;
      border-radius: 12px;

      .ql-editor {
        min-height: inherit;

        &::-webkit-scrollbar {
          width: 4px;
          border-radius: 2px;
        }
      }
    }
    .col-xl-4 {
      width: 100%;
      max-width: 100%;
      flex: auto;
      padding-right: 0;
    }
  }
}

.exclaimIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  line-height: 16px;
  margin-right: 0.3em;
  background: var(--zq-el-grey-bg);
  color: white;
}

.exclaimPartialCompleteIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 19px;
  height: 19px;
  border-radius: 50%;
  line-height: 16px;
  margin-right: 0.3em;
  background: var(--zq-el-pink-bg);
  color: white;
  &__active {
    opacity: 0.5;
  }
}

.c-nxt-app__main-theme .translation-tab-button .check-icon {
  background: var(--zq-el-green-bg);
  &__active {
    background: lighten(#3bb54c, 15%);
  }
}

</style>
